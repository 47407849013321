import React, {useRef} from 'react';
import {Header} from './Components/Header/Header';
import {classes, st} from './SideCart.st.css';
import {Footer} from './Components/Footer/Footer';
import {Body} from './Components/Body/Body';
import './vars.scss';
import {SideCartAppDataHook, SideCartProps} from './SideCart.types';

export const SideCart = ({dir}: SideCartProps) => {
  const editorBrand = (window as any)?.commonConfig?.brand;
  const isStudioOrEditorXSite = editorBrand === 'editorx' || editorBrand === 'studio';
  const closeButtonRef = useRef<HTMLButtonElement>(null);
  const onRemoveItem = () => closeButtonRef?.current?.focus();

  return (
    <main
      dir={dir}
      data-hook={SideCartAppDataHook.root}
      className={st(
        'cartBodyMainOneColumn',
        'sideCart',
        classes.container,
        ...(isStudioOrEditorXSite ? [classes.responsiveEditor] : [])
      )}
      data-wix-side-cart>
      <div className={classes.headerAndBody}>
        <Header closeButtonRef={closeButtonRef} />
        <Body onRemoveItem={onRemoveItem} />
      </div>
      <div className={classes.footer}>
        <Footer />
      </div>
    </main>
  );
};
